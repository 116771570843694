.coursemanager{
    background: linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.5)),url(/public/bgpattern2.png);
    min-height: 100vh;
    background-size: contain!important;
    background-repeat: repeat!important;
    background-position: center!important;
}
.managertopbar{
    background: #fff;
    padding: 20px 0;
    border-bottom: 1px solid var(--color1);
}
.managetopbarbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mtop a{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textmaincolor);
}
.cmanginfobox{
    background: #fff;
    border-radius: 10px;
    margin: 50px auto;
    box-shadow: var(--box1);
}
.cmangemain_image{
    width: 100%;
}
.cmangemain_image img{
    width: 100%;
    object-fit: contain;
}
.manegmname{
    margin: 10px auto;
}
.manegmname p{
    font-size: 20px;
    color: var(--color8);
    font-weight: 600;
}
.manegmsub p{
    font-size: 14px;
    color: var(--color8);
}
.mangeinstrutor ul{
    list-style-type: none;
    gap:10px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px auto;
    background: var(--lightbg);
    border-radius: 5px;
}
.mangeinstrutor ul li{
    display: flex;
    width: calc(50% - 10px);
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px 0;
}
.mangeinstrutor ul li p{
    display: block;
    width: 100%;
    margin: 10px auto 0 auto;
    text-align: center;
    font-size: 14px;
    color: var(--color6);
    font-weight: 500;
}
.mangeinstrutor li .maininsttxt{
    margin-bottom: 10px;
    margin-top: 3px;
    font-size: 14px;
    font-weight: 600;
    color: var(--textmaincolor);
}
.datasmanegbx{
    margin: 10px auto;
}
.datasmgtop{
    background: var(--lightbg);
    padding: 10px;
    width: calc(95% - 0px);
    border-radius: 5px;
}
.knowmoremaneg span{
    display: block;
    width: 100%;
    padding: 5px 0px;
    color: #fff;
    background: var(--maincolor);
    margin: 0 auto;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
}
.datasmgtop p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 600;
}
.cmanginfobox{
    margin: 50px auto!important;
}
.datasmang ul{
    list-style-type: none;
}
.datasmang li{
    width: calc(95% - 20px);
    padding: 10px 10px 5px 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid var(--color1);
    color: var(--color8);
}
.datasmang li:nth-last-child(1){
    border-bottom: 0;
}
.datasmang li .othertxtmange{
    font-weight: normal;
}
.coursemanagerbox{
    border-radius: 5px; 
    margin: 50px auto!important;
}
.sboxflex{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding: 20px 0 10px 0;
}
.search-manager{
    background: #fff;
    border: 2px solid var(--color1);
    border-radius: 5px;
    padding-right: 10px;
    display: flex;
    align-items: center;
}
.search-manager svg{
    display: block;
    height: 20px;
    width: 20px;
    position: relative;
    color: var(--color6);
}
.search-manager input{
    padding: 10px 10px;
    font-size: 14px;
    color: var(--color8);
    width: 350px;
    border: 0;
    border-radius: 5px;
}
.leftside-searchbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
}
.viewdesign{
    padding: 7px 15px;
    background: transparent;
    color: var(--color6);
    cursor: pointer;
    transition: .5s;
    border: 0;
    border-radius:5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap:10px;
    border: 2px solid var(--color1);
}
.viewdesign svg{
    height: 18px;
    width: 18px;
}
.actdesign{
    background: var(--lightbg2);
    border: 2px solid var(--textmaincolor);
    color: #000;
}
.viewdesign:hover{
    background: var(--lightbg2);
    border: 2px solid var(--textmaincolor);
    color: #000;
}
.profile-circle{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden;
}
.profile-circle img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.rightside-searcbox{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.tabmaneg{
    background: #fff;
    border-radius: 5px;
    margin: 10px auto!important;
    position: relative;
    gap: 10px;
    cursor: pointer;
    transition: .5s;
    border: 2px solid var(--color1);
}
.tabmaneg a{
    display: flex;
    align-items: center;
    width: 100%;
}
.newcircle{
    position: absolute;
    top: 5px;
    left: 2%;
    padding: 0px 5px;
    font-size: 10px;
    background: var(--secondarycolor);
    border-radius: 3px;
    z-index: 3;
    display: block;
    color: #fff;
}
.tabmaneg.activemtab {
    background: var(--lightbg2);
    border: 2px solid var(--textmaincolor);
}
.tabmaneg::before{
    content: "";
    height: 54px;
    width: 54px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    background: var(--bggradient);
    left: 8px;
    top: 8px;
}
.tabmangeicon{
    position: relative;
    z-index: 2;
    margin: 10px;
    height: 50px;
    width: 50px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tabmangeicon img{
    height: 70%;
    width: 70%;
    object-fit: contain;
}
.tabmangetxt p{
    font-size: 18px;
    font-weight: 600;
    color: var(--color8);
}
.managerdefimg{
    height: 250px;
    background: var(--maincolor);
    display: flex;
    align-items: center;
    justify-content: center;
}
.managerdefimg img{
    width: 70%;
    height: 70%;
    object-fit: contain;
}
.fileslist{
    margin: 10px auto 20px auto;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 20px)!important;
    box-shadow: var(--box1);
}
.filepath{
    margin: 20px auto 10px auto;
}
.filepath ul{
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 10px;
}
.filepath li{
    font-size: 14px;
    font-weight: 600;
    transition: .5s;
    color: var(--color8);
    cursor: pointer;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.filepath li a{
    transition: .5s;
    text-decoration: none;
    color: var(--color8);
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 10px);
    display: block;
    float: left;
}
.compulasrybox{
    background: #fff;
}
.compulsflx{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.compulsflx button{
    padding: 8px 20px;
    background: var(--textmaincolor);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
}
.comploading{
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.headings-compul p{
    font-size: 16px;
    font-weight: 600;
    color: var(--color8);
}
.filepath li p{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.filepath li a:hover{
    color: var(--textmaincolor);
}
.topfilebar p{
    font-size: 16px;
    font-weight: 600;
    color: var(--color8);
    padding: 10px 0;
}
.listboxflx,.topbarflx{
    display: flex;
    gap:0 10px;
    align-items: center;
}
.mainlistbox{
    width: 100%;
    position: relative;
    cursor: pointer;
    transition: .5s;
}
.mainlistbox:hover{
    background: var(--lightbg);
}
.listboxflx{
    border-top: 1px solid var(--color1);
    padding: 10px 0;
    transition: .5s;
}
.fileimg{
    position: relative;
    z-index: 2;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--lightbg2);
    border-radius: 50%;
    transition: .5s;
}
.fileimg img{
    width: 60%;
    height: 60%;
    object-fit: contain;
}
.getfl{
    transition: .5s;
}
.filenambox p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 600;
}
.fileavailable{
    width: calc(30% - 40px)!important;
}
.fileavailable p{
    font-size: 14px;
    color: var(--color8);
}
.getfl{
    width: 30px;
}
.getstartfile{
    display: flex;
    align-items: center;
    justify-content: center;
    border:0;
    cursor: pointer;
    color: var(--maincolor);
    background: transparent;
}
.getstartfile svg{
    height: 25px;
    width: 25px;
}
.fileimagebox{
    width: 60px;
}
.filenambox{
    width: calc(70% - 0px)!important;
    transition: .5s;
}
.datasidebox{
    width: calc(100% - 70px);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.mainlistbox.gridmainbox{
    width: 25%;
    float: left;
    display: flex;
}
.mainlistbox.gridmainbox:hover{
    background: transparent;
}
.mainlistbox.gridmainbox:hover .listboxflx{
    background: var(--lightbg);
}
.mainlistbox.gridmainbox .listboxflx{
    flex-wrap: wrap;
    padding: 10px;
    width: calc(90% - 20px);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    transition: .5s;
    margin: 10px auto;
}
.mainlistbox.gridmainbox .availgrdtxt{
    display: block;
    font-weight: 500;
    color: var(--color6);
}
.mainlistbox.gridmainbox .fileavailable p{
    color: red;
}
.gridmainbox .fileimagebox{
    width: 100%;
}
.mainlistbox.gridmainbox .filenambox{
    width: 100%!important;
    margin: 10px auto;
}
.mainlistbox.gridmainbox .fileavailable{
    width: 100%;
    margin: 0px auto 10px auto;
}
.mainlistbox.gridmainbox .getfl{
    width: 100%;
}
.checked svg{
    height: 25px;
    width: 25px;
    color: green;
}
.mainlistbox.gridmainbox a{
    display: flex;
    width: 100%;
    text-decoration: none;
}
.loadingbxmaneg .fileimg{
    background: transparent;
}
.nodataimg{
    height: 300px;
    width: 300px;
    margin: 20px auto;
}
.nodataimg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.nofiles p{
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    color: var(--color8);
}
.nodatabox{
    padding: 0px 0 30px 0;
}
.videopage{
    background: #fff;
    /* padding: 50px 0; */
    min-height: 100vh;
    background: var(--lightwhite);
    /* min-height:calc(100% - 100px); */
    background-size: contain!important;
    background-repeat: repeat!important;
    background-position: center!important;
    /* padding: 50px 0; */
}
.grdmsg p{
    font-size: 14px;
    color: rgba(0,0,0,0.8);
    font-weight: 500;
}
.contentside .videopage{
    padding: 0;
}
.videobox-vidpage{
    min-height: 70vh!important;
    border-radius: 5px;
    background: #fff;
}
.loadingbxvid{
    display: flex;
    align-items: center;
    justify-content: center;
}
.videodata {
    margin: 10px auto 20px auto;
}
.videonambox p{
    font-size: 20px;
    font-weight: 600;
    color: var(--maincolor);
}
.plyr--video{
    height: 80vh!important;
}
.oplayer{
    content: "";
    position: absolute;
    background: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    z-index: 3;
    top: 0;
    left: 0;
    transition: .5s;
    opacity: 0;
}
.Watermark{
    background-color: rgba(255,255,255,0.5);
    padding: 10px;
    position: absolute;
    z-index: 99999;
    pointer-events: none;
    font-size: 10px;
    line-height: 12px;
    border-radius: 10px;
    text-align: left;
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
body .Watermark{
    display: block!important;
}
.plyr__video-wrapper .Watermark{
    display: block!important;
}
.plyr_menu_container{
    min-width: 150px;
}
.plyr_progress .plyr_tooltip{
    min-width: 150px;
}
:root {
    --plyr-color-main: var(--textmaincolor);
}
.videonambox{
    background: var(--lightbg2);
    padding: 10px 0;
}
.videocontainer{
    position: relative;
}
.languagesbox{
    position: absolute;
    bottom: 50px;
    color: #fff;
    text-align: center;
    display: none;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}
.langbuttons button{
    padding: 5px 20px;
    border-radius: 30px;
    background: rgba(255,255,255,.2);
    backdrop-filter: blur(40px);
    cursor: pointer;
    color: var(--color8);
    font-size: 14px;
    border: 2px solid #fff;
}
.rightvidbuttons{
    display: flex;
    align-items: center;
    gap:10px;
}
.langbuttons{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}
.langbuttons button.actlang{
    background: #fff;
    color: var(--color8);
}
.flx-vids{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 0px 0 20px 0;
}
.flx-vids a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    gap: 5px;
    transition: .5s;
    border-radius: 5px;
}
.flx-vids a svg{
    height: 20px;
    width: 20px;
}
.backbutton{
    background: #fff;
    padding: 8px 20px;
    color: var(--color8);
    font-weight: 500;
    border: 2px solid var(--color1);
}
.backbutton:hover{
    background: var(--textmaincolor);
    border: 2px solid var(--textmaincolor);
    color: #fff;
}
.nextbutton{
    background: #FFFFFF;
    color: var(--maincolor);
    padding: 8px 20px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    gap: 5px;
    transition: .5s;
    cursor: pointer;
    border-radius: 5px;
}
.feedpop{
    position: fixed;
    height: 100vh;
    background: rgba(255,255,255,.5);
    z-index: 9999;
}
.feedpopbox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.feedpopmid{
    width: 96%;
    max-width: 500px;
    min-height: 1px;
    overflow: hidden;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 30px 0;
    position: relative;
}
.closefeedpop{
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    cursor: pointer;
    background: var(--lightbg2);
    border-radius: 0 0 0 10px;
}
.closefeedpop svg{
    height: 20px;
    width: 20px;
    padding: 5px;
    display: block;
}
.feedhead p{
    font-size: 20px;
    font-weight: 600;
    color: var(--color8);
}
.reactions{
    margin: 20px auto 20px auto;
    
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(5, 1fr);
    
    /* display: flex;
    gap: 0 10px;
    justify-content: space-between; */
}
.reactionbox{
    padding:5px 0px;
    border-radius: 8px;
    transition: .5s;
    cursor: pointer;
}
.reactionbox:hover{
    background: var(--lightbg);
}
.reactionbox.selectedreact{
    background: var(--lightbg);
}
.reactionicon{
    height: 50px;
    width: 50px;
    margin: 0 auto;
}
.reactionicon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.reactiontxt p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 600;
    text-align: center;
}
.reaction-message textarea{
    padding: 10px;
    border: 1px solid var(--color1);
    border-radius: 5px;
    width: calc(100% - 22px);
}
.submit-reaction button{
    padding: 8px 30px;
    border-radius: 5px;
    background: var(--textmaincolor);
    cursor: pointer;
    border: 0;
    font-size: 14px;
    color: #fff;
    margin: 10px auto;
}
.submittin svg{
    animation: goRound 1s linear infinite;
    height: 20px;
    width: 20px;
}
.submittin{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
@keyframes goRound {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.youtubevideopage{
    height: 80vh;
    width: 100%;
    display: block;
    margin: 0;
    border-width: 0;
}
.imagepg{
    background: #fff;
    border-radius: 10px;
    margin: 10px auto!important;
}
.imagepg .pdfcontainer img{
    width: 100%;
    object-fit: contain;
}
.uploadpop{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100vh;
}
.uploadpopbx{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(255,255,255,.5);
}
.uploadbox{
    position: relative;
    width: 500px;
    background: #fff;
    margin: 10px auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.uploadmbox{
    height: 250px;
    border: 2px dashed var(--textmaincolor);
    border-radius: 10px;
    margin: 30px auto!important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploadheading p{
    font-size: 20px;
    font-weight: 600;
    color: var(--textmaincolor);
}
.browsebutton span{
    margin: 0px auto;
    padding: 8px 10px;
    border-radius: 5px;
    background: var(--textmaincolor);
    color: #fff;
    border: 0;
    display: block;
    white-space: nowrap;
    width: calc(100% - 20px);
    cursor: pointer;
}
.browsebutton label{
    width: 100%;
}
.browsebutton p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 500;
}
.browsebutton{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:0 10px;
}
.uploadicon svg{
    height: 60px;
    width: 60px;
    margin: 10px auto;
    color: var(--maincolorlight);
}
.uploadmbox label{
    width: 100%;
    display: block;
    height: 100%;
    display: flex;
    align-items: center;
}
.closeupload{
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    background: var(--lightbg2);
    border-radius: 0 0 0 10px;
    cursor: pointer;
}
.closeupload svg{
    display: block;
    height: 20px;
    width: 20px;
    color: var(--color8);
}
.nextupload svg{
    height: 20px;
    width: 20px;
}
.previewpop .uploadpopbx{
    flex-wrap: wrap;
    background: #fff;
}
.proceedbox button{
    margin: 10px auto;
    padding: 8px 30px;
    background: var(--textmaincolor);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
}
.previewImages{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px;
    max-height: 80vh;
    overflow: auto!important;
    margin: 20px;
}
.previewImages .imagebox-upload{
    width: calc(33.33% - 20px);
    height: 250px;
    margin: 20px 10px 10px 10px;
    position: relative;
}
.previewImages .imagebox-upload img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.imageuploads{
    width: 50%;
    padding: 0 0px;
    display: flex;
    flex-wrap: wrap;
}
.deleteimg{
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    background: var(--lightbg2);
    cursor: pointer;
}
.deleteimg svg{
    height: 20px;
    width: 20px;
    padding: 5px;
    display: block;
}
.fixedbottombr{
    position: sticky;
    bottom: 0px;
    padding: 20px 0;
}
.fixbximgs{
    display: flex;
    justify-content: space-between;
}
.fixbximgs button{
    margin: 0;
    padding: 8px 30px;
    background: var(--textmaincolor);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
}
.fixbximgs label span{
    display: block;
    width: max-content;
    background: var(--lightwhite);
    border-radius: 5px;
    padding: 8px 30px;
    cursor: pointer;
    color: var(--textmaincolor);
    font-size: 14px;
}
.imageloadbox{
    height: 400px;
}
.sucessassign{
    padding: 30px 0;
}
.oktext button{
    padding: 8px 40px;
    background: var(--lightbg2);
    color: var(--color8);
    margin: 10px auto;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.uploaded {
    height: 25px;
    width: 25px;
    color: #66B26A;
}
.uploadsuc{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    gap: 10px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
}
.resultbox{
    padding: 20px 0;
    display: flex;
    height: 100%;
}
.sucessresul{
    background: #dfffdf;
    border-radius: 10px;
}
.sucessresul .resultsuccess p{
    color: green;
}
.resultsuccess p{
    font-size: 25px;
    font-weight: 600;
}
.resultimg{
    height: 80px;
    width: 80px;
    margin: 10px auto;
}
.resultimg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sucessresul .resultlist p{
    font-size: 16px;
    font-weight: 500;
}
.sucessresul .resultlist span{
    color: green;
    font-size: 20px;
    margin-left: 5px;
}
.congratstext p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 600;
}
.congratstext{
    margin: 10px auto;
}
.boxrejected{
    padding: 20px 0;
    background: rgb(255 234 234);
    border-radius: 10px;
}
.boxrejected .resultsuccess p{
    color: red;
}
.boxrejected .resultlist p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 600;
    line-height: 25px;
}
.boxrejected .resultlist{
    margin: 10px auto;
}
.examinstuctions{
    margin-bottom: 10px;
    padding: 10px 0;
}
.examinstuctions p{
    font-size: 16px;
}
.lower-instruc-boxes{
    margin-top: 20px;
    float: left;
}
.lower-instruc-boxes .leftbxinst .examinstuctions{
    border-radius: 0 5px 5px 0;
}
.lower-instruc-boxes .rightbxinst .examinstuctions{
    border-radius: 5px 0px 0px 5px;
}
.instruclist{
    padding: 0px 0 10px 0;
}
.instruclist ul{
    list-style-type: none;
}
.instruclist li{
    padding: 10px;
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    border-bottom: .5px solid rgba(0,0,0,0.1);
    font-size: 16px;
    color: var(--color8);
}
.instruclist li span{
    font-weight: 600;
}
.attemptslist li{
    font-size: 14px;
}
.attemptslist li p svg{
    height: 18px;
    width: 18px;
    margin-left: 5px;
    position: relative;
    top: 2px;
    color: var(--maincolor);
}
.noattemps{
    width: 100%;
    height: 200px;
}
.noattemps img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.instructbox{
    min-height: auto!important;
    margin-bottom: 20px!important;
}
.noattemsp p{
    font-size: 14px;
    font-weight: 600;
    color: var(--color8);
}
.lower-instruc-boxes .w50{
    padding: 20px 0;
}


/* ******************Quiz ****************************** */
.quizpage{
    min-height: calc(100vh - 40px);
    padding: 20px 0;
    background-size: contain!important;
    background-repeat: repeat!important;
    background-position: center!important;
    background: linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.5)),url(/public/bgpattern2.png);
}
.quizboxmain{
    background: #fff;
    border-radius: 5px;
    margin: 10px auto!important;
    border: 1px solid rgba(0,0,0,0.1);
    display: flex;
}
.questionsside{
    background: #fff;
    width: calc(100% - 380px);
    min-height: 1px;
    overflow: hidden;
    position: relative;
}
.questions-listside{
    background: #fff;
    width: 380px;
    min-height: 1px;
    overflow: hidden;
}
.questions-listside .questwhitearea{
    width: 100%;
}
.questionslist{
    padding: 10px 0 0 0;
    max-height: calc(100vh - 450px);
}
.questionhead p{
    font-size: 18px;
    font-weight: 600;
    color: var(--color8);
}
.qlistquiz{
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    /* max-height: calc(100vh - 500px); */
    overflow-x: auto!important;
}
.qmbox{
    width: 40px;
    height: 40px;
    background: var(--color1);
    margin: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s;
}
.qmbox:hover{
    box-shadow: inset 0 0 100px rgba(0,0,0,0.08);
}
.qmbox p{
    font-size: 18px;
    color: var(--color8);
    font-weight: 500;
}
.qmbox.colored p{
    color: #fff;
}
.abbrevqbox{
    background: var(--lightwhite);
    border-radius: 10px;
    margin: 0 auto 10px auto;
}
.abbflx{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;
}
.abbflx .qmbox{
    height: 30px;
    width: 30px;
}
.abbflx .qmbox svg{
    height: 20px;
    width: 20px;
}
.abbflx .qmbox p{
    font-size: 14px;
}
.abbflx .abvtxt{
    width: calc(100% - 55px);
    font-size: 14px;
    font-weight: 500;
    color: var(--color8);
    margin-left: 10px;
}
.qmbox.answered{
    background: #8eed7d;
}
.qmbox.answered p{
    color: #fff;
}
.qmbox.answered svg{
    color: #fff;
}
.qmbox.notans{
    background: #FAE8E6;
}
.qmbox.notans p{
    color: #D12D14;
}
.qmbox.notans svg{
    color: #D12D14;
}
.qmbox.reviewd{
    background: #FAF2E5;
}
.qmbox.reviewd p{
    color: #D2901F;
}
.qmbox.reviewd svg{
    color: #D2901F;
}
.qmbox.ansreved{
    background: #E6EFFF;
}
.qmbox.ansreved p{
    color: #2D7CFF;
}
.qmbox.ansreved svg{
    color: #2D7CFF;
}
.quiztopflx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px;
    width: calc(90% - 20px)!important;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.1);
}
.quiz-name p{
    font-size: 16px;
    font-weight: 600;
    color: var(--maincolor);
}
.submitquiz button{
    padding: 8px 40px;
    border: 0;
    border-radius: 5px;
    background: var(--textmaincolor);
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    transition: .5s;
}
.submitquiz button:hover{
    box-shadow: inset 0 0 100px rgba(0,0,0,0.2);
}
.submitquiz{
    display: flex;
    gap:10px;
    align-items: center;
    justify-content: flex-start;
}
.instructions{
    padding: 8px 10px;
    background: var(--lightwhite);
    color: var(--textmaincolor);
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    transition: .5s;
} 
.instructions:hover{
    box-shadow: inset 0 0 100px rgba(0,0,0,0.1);
}
.instructions svg{
    height: 18px;
    width: 18px;
    position: relative;
    top: 3px;
    margin-right: 5px;
}
.bottombarflx{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 10px 0;
    width: 98%;
}
.lbuttons,.rbuttons{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.botttombar-quiz{
    position: sticky;
    bottom: 0;
}
.bottombarflx button{
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    transition: .5s;
}
.lbuttons button{
    padding: 8px 10px;
    background: var(--lightwhite);
    color: var(--textmaincolor);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    transition: .5s;
}
.rev-but{
    background: #FAF2E5;
    color: #D2901F;
}
.unattemp-but{
    background: #FAE8E6;
    color: #D12D14;
}
.savenext-but{
    background: #47c930;
    color: #fff;
}
.questopbflx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
.quiz-time{
    padding: 8px 10px;
    background: var(--lightwhite);
    color: var(--textmaincolor);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.quiz-time svg{
    height: 18px;
    width: 18px;
    position: relative;
    margin-right: 5px;
}
.clockbox{
    display: flex;
    align-items: center;
    justify-content: center;
}
.quest-act p ,.quest-type p{
    font-size: 16px;
    font-weight: 600;
    color: var(--color8);
}
.mainquestionbox{
    /* min-height: 400px!important; */
    padding: 10px 0;
    max-height: calc(100vh - 270px);
    background: var(--lightwhite);
    overflow: auto!important;
}
.questdiv{
    margin: 10px auto!important;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 5px;
}
.questtext {
    padding: 20px 0;
}
.qheadin p{
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color8);
}
.answerhead p{
    font-size: 14px;
    font-weight: 500;
    color: var(--color8);
}
.answerhead{
    margin-bottom: 5px;
}
.answersbx{
    margin: 10px auto;
}
.answerboxmain{
    margin: 5px auto!important;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    cursor: pointer;
    border: 1px solid #fff;
}
.answerbx{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 0;
}
.answeropt{
    height: 30px;
    width: 30px;
    background: var(--color1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--color8);
}
.answerboxmain:hover .answeropt{
    background: #47c930;
    color: #fff;
}
.answerboxmain.answered .answeropt{
    background: #47c930;
    color: #fff;
}
.answerboxmain.answered{
    border: 1px solid #47c930;
}
.answerboxmain.notans{
    border: 1px solid #D12D14;
}
.answerboxmain.notans .answeropt{
    background: #D12D14;
    color: #fff;
}
.answerboxmain.notans:hover{
    border: 1px solid #D12D14!important;
}
.answerboxmain.notans:hover .answeropt{
    background: #D12D14!important;
    color: #fff!important;
}
.answemainside{
    width: calc(100% - 40px);
    padding-top: 5px;
}
.answerbx textarea{
    padding: 10px;
    border-radius: 5px;
    width: calc(100% - 22px);
    border: 1px solid rgba(0,0,0,0.1);
    font-size: 14px;
    line-height: 25px;
    color: var(--color8);
    transition: .5s;
    margin-top: 5px;           
}
.answerbx textarea:focus{
    border: 1px solid #2D7CFF;
}
.answerbx input:focus{
    border: 1px solid #2D7CFF;
}
.answerbx label{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: var(--color8);
}
.answerbx input{
    padding: 10px;
    border-radius: 5px;
    width: calc(100% - 22px);
    border: 1px solid rgba(0,0,0,0.1);
    font-size: 14px;
    line-height: 25px;
    color: var(--color8);
    transition: .5s;
    margin-top: 5px;
}
.submitpop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100vh;
}
.subpopbox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(255,255,255,0.5);
    backdrop-filter: blur(10px);
}
.submitconfirmbox{
    width: 450px;
    background: #fff;
    border-radius: 10px;
    border: 2px solid rgba(0,0,0,0.1);
    padding: 30px 0;
}
.submithead p{
    font-size: 20px;
    font-weight: 600;
    color: var(--color8);
}
.submitsubhead p{
    font-size: 14px;
    color: var(--color8);
    line-height: 25px;;
}
.submitpop .abbrevqbox{
    background: #fff;
}
.submitpop .qinsttype{
    margin: 20px auto;
}
.instuctbox{
    background: #fff;
    max-height: calc(70vh)!important;
    overflow: auto;
    border-radius: 10px;
    margin: 10px auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding-bottom: 20px;
    position: relative;
}
.closeinstructions{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.closeinstructions svg{
    height: 20px;
    width: 20px;
}
.resumebuttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.resumebuttons button{
    padding: 8px 20px;
    border: 0;
    border-radius: 5px;
    transition: .5s;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
.resumebutton{
    background: var(--lightbg);
    color: var(--maincolor);
}
.submbutton{
    background: var(--maincolor);
    color:#fff;
}
.topbxresult{
    background: #fff;
    padding: 0px 0 20px 0;
    border-radius: 10px;
    margin: 10px auto!important;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    position: sticky;
    position: -webkit-sticky;
    /* top: 50px; */
}
.lowersideresult {
    margin: 0px auto 10px auto;
}
.lowersideresult .qmbox{
    background: transparent!important;
    width: auto;
    height: auto;
    margin: 5px 10px;
}
.lowersideresult .qmbox:hover{
    box-shadow: none;
    cursor: auto;
}
.lowersideresult .qmbox.answered p{
    color: #47c930;
}
.markssplibx .headingsresultbox{
    background: var(--lightwhite);
    padding: 10px 0;
    border-radius: 5px 0 0 5px;
}
.markssplibx .w50:nth-child(2) .headingsresultbox{
    border-radius: 0px 5px 5px 0px;
}
.markssplibx .headingsresultbox p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 600;
}
.markssplibx .w50{
    width: calc(50% - 1px);
    border-right: 1px solid rgba(0,0,0,0.1);
}
.markssplibx .w50:nth-child(2){
    border-left: 1px solid rgba(0,0,0,0.1);
    border-right: 0;
}
.resultcount{
    padding: 10px 0;
}
.resultcount p{
    font-size: 14px;
    font-weight: 600;
    color: var(--color8);
}
.obtmarks p{
    color: #47c930;
}
.deductmarks p{
    color: #D12D14;
}
.topbxresultmain{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.resulttoptxt p{
    font-size: 14px;
    font-weight: 600;
    color: var(--color8);
    text-align: justify;
    padding-top: 20px;;
}
.scoredbox{
    margin: 5px auto;
}
.scoredbox p{
    font-size: 14px;
    color: var(--color6);
    font-weight: 600;
    text-align: center;
    font-size: 20px;
}
.scoredbox p span{
    color: var(--color8);
}
.topresulthead p{
    font-size: 18px;
    color: var(--color8);
    font-weight: 600;
}
.topresulthead{
    padding: 10px 0;
    background: var(--lightbg);
}
.answersside .topresulthead{
    background: transparent;
    padding: 0;
}
.topbxresultmain {
    padding: 20px 0;
}
.answersside{
    margin: 10px auto;
}
.answersbox{
    background: var(--lightwhite);
    padding: 10px 0;
    margin: 10px auto!important;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.answers-topbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 10px;
}
.answerightside {
    display: flex;
    gap: 10px;
}
.answerightside p{
    font-size: 14px;
    color: var(--color8);
    font-weight: 500;
}
.answerslside p{
    font-size: 16px;
    color: var(--color8);
    font-weight: 600;
}
.posmarks span{
    color: #47c930;
}
.negmarks span{
    color: #D12D14;
}
.earnedmarks span{
    color: #2D7CFF;
}
.resultbx{
    display: flex;
}
.youransbx .answerbx p{
    font-size: 14px;
    font-weight: 600;
    color: var(--color8);
}
.answersmainbxresult .answerboxmain:hover .answeropt{
    background: var(--color1);
    color: var(--color8);
}
.answersmainbxresult .answerboxmain.answered:hover .answeropt{
    background: #47c930;
    color: #fff;
}
.notgenerated{
    background: #fff;
    margin: 10px auto;
    padding: 20px 0;
    border-radius: 10px;
}
.notimg{
    width: 80%;
    height: 400px;
    margin: 10px auto;
}
.notimg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.notgenerated p{
    font-size: 25px;
    font-weight: 600;
    color: var(--maincolor);
}
.notgenerated button{
    padding: 8px 30px;
    border-radius: 5px;
    background: var(--textmaincolor);
    font-size: 14px;
    color: #fff;
    border: 0;
    margin: 10px auto;
    cursor: pointer;
}
.goback button{
    padding: 8px 20px;
    background: var(--textmaincolor);
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.goback button svg{
    width: 16px;
    height: 16px;
}
.bookbutton svg{
    height: 20px;
    width: 20px;
    color: var(--maincolor);
    cursor: pointer;
}
.bookbutton{
    font-size: 12px;
    font-weight: 500;
    color: var(--color8);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.bookingflx{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:20px;
}
.courseexpired{
    width: 400px;
    height: 400px;
    margin: 0 auto;
}
.courseexpired img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.cexpired{
    margin: 0px auto 10px auto;
}
.cexpired p{
    font-size: 20px;
    font-weight: 600;
    color: var(--maincolor);
}
.expiredpage{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.backboxcomp{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.pathflx{
    display: flex;
    justify-content: space-between;
}
.pathflx .managerbck{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.pathflx ul{
    width: calc(100% - 70px);
}
.managerbck button{
    width: 100%;
    padding: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    border: 0;
    background: var(--textmaincolor);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.notgensub p{
    font-size: 14px!important;
    color: var(--color6)!important;
    text-align: center;
}
.fromname{
    margin-bottom: 20px!important;
}
.lsideassing{
    display: flex;
    align-items: center;
    gap: 10px;
}
.lsideassing p{
    color: #FFFFFF;
}
.videonmflx{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}
.postpop{
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: var(--color5);
}
.postpopbox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.postpopdata{
    width: 700px;
    min-height: 100px;
    max-height: 80vh;
    overflow: auto;
    border-radius: 10px;
    border: 3px solid var(--color2);
    background: #fff;
    padding: 10px 0 60px 0;
    position: relative;
}
.postmsgbuttons{
    position: absolute;
    bottom: 0px;
    left: 0;;
}
.mintimimg{
    height: 200px;
    width: 100%;
    margin: 10px auto;
}
.mintimimg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.mintimetext p{
    font-size: 14px;
    font-weight: 500;
    color: var(--color8);
}
.mintimetext{
    margin-bottom: 10px;
}
.availgrdtxt{
    font-weight: 500;
}
.ateemptanswe{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.correctans {
    font-size: 14px;
    font-weight: 500;
    color: #47c930;
}
.incorrectans{
    font-size: 14px;
    font-weight: 500;
    color: #D12D14;
}
.leaderstable table,.leaderstable tbody{
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}
.leadwbox{
    background: #fff;
    border-radius: 10px;
}
.leaderstable th,td{
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.leaderstable th{
    color: rgba(0,0,0,0.8);
}
.leaderstable td{
    color: rgba(0,0,0,0.6);
}
.leaderstable tr:nth-last-child(1) td{
    border-bottom: 0;
}
.redirectpop{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: var(--color5);
    z-index: 999;
}
.redipopflx{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.redpopbox .postmsgbuttons{
    position: relative;
    margin-top: 20px;
}
.autohbox .videobox-vidpage{
    min-height: auto!important;
}
.livetxt{
    background: red;
    animation: scaleupdown2 1s linear infinite;
}
@keyframes scaleupdown2{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.05);
  }
  100%{
    transform: scale(1);
  }
}
.timebox{
    display: flex;
    justify-content: center;
    width: max-content;
    border: 2px solid var(--textmaincolor);
    background: var(--lightbg);
    margin: 10px auto;
    border-radius: 5px;
}
.timer{
    padding: 6px 10px;
    width: 60px;
    margin: 5px 0;
    border-right:  2px solid var(--textmaincolor);
}
.nofiles p.timersub{
    font-size: 14px;
    font-weight: 500;
}
.timersub{
    font-size: 14px;
    font-weight: 500;
}
.livetimer{
    margin-top: 20px;
}
.timer:nth-child(4){
    border-right:  0px ;
}
.notavailpop{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100vh;
}
.notavailbox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(255,255,255,.5);
}
.notavailpflex{
    width: 600px;
    background: #fff;
    position: relative;
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;
}
.closenot svg{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background: var(--color8);
    color: #fff;
    cursor: pointer;
    border-radius: 0 0 0 10px;
}
.sidebarflexmng{
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
.sidebarside{
    width: 320px;
    background: #fff;
    height: calc(100vh - 20px);
    overflow: auto;
    padding-bottom: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    /* transition: .3s; */
}
.contentside{
    width: calc(100% - 320px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 20px;
    /* transition: .3s; */
    background: var(--lightwhite);
}
.topsidecontent{
    background: var(--maincolor);
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    position: sticky;
    top: 0;
    position: -webkit-sticky;
    z-index: 999;
}
.topflflex{
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:20px;
}
.topnameside{
    display: flex;
    gap: 10px;
}
.backbtn{
    padding: 8px 0 7px 0;
    background: var(--maincolor);
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.contentlist{
    margin: 10px auto;
}
.contentlist ul{
    list-style-type: none;
}
.contentlist li .sidebarlist {
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    transition: .3s;
    width: calc(100% - 20px);
}
.contentlist li .sidebarlist:hover{
    background: var(--maincolor);
}
.contentlist li.actsid .sidebarlist{
    background: var(--maincolor);
}
.contentlist li .sidebarlist:hover .filenames{
    color: #fff;
}
.contentlist li.actsid .sidebarlist .filenames{
    color: #fff;
}
.sidebarlist .fileimg{
    height: 30px;
    width: 30px;
}
.sidebarlist .filenames{
    width: calc(100% - 40px);
    font-size: 14px;
    color: var(--color8);
    font-weight: 500;
    line-height: 25px;
}
.backbtn a{
    padding: 10px;
    width: calc(100% - 20px);
    background: #FFF;
    border-radius: 5px;
    font-size: 14px;
    display: block;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    color: var(--maincolor);
}
.topnameside{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}
.topnameside .closemenu{
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background: var(--lightbg);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.topmname p{
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600;
}
.videofaqs{
    margin: 20px auto;
}
.faqslist .tabboxes:nth-child(1){
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-top: 20px;
}
.faqqstitle p{
    font-size: 22px;
    color: var(--color8);
    font-weight: 600;
}
.tabmaneg .livetxt{
    left: auto;
    right: 10px;
}
.packagepgcourses{
    width: 100%!important;
    box-shadow: none!important;
    padding: 0!important;
}

.resultextside{
    width: 100%!important;
}
.goback{
    width: 70px;
}
@media only screen and (max-width: 900px){
    .cdatabox{
        display: none;
        width: 100%!important;
    }
    .cmanbox{
        width: 100%!important;
    }
    .sboxflex{
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .leftside-searchbox{
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
    }
    .search-manager{
        width: 100%;
    }
    .search-manager input{
        width: calc(100% - 40px);
    }
    .tabsmanagerflx .w33{
        width:33.33%;
    }
    .tabmaneg a{
        flex-wrap: wrap;
    }
    .tabmaneg .livetxt{
        left: auto;
        right: 3px;
    }
    .tabmangetxt{
        width: calc(100% - 20px);
        text-align: center;
        padding: 10px 10px 2px 10px;
    }
    .tabmangetxt p{
        font-size: 12px;
    }
    .tabmangeicon{
        height: 30px;
        width: 30px;
        margin: 10px auto 0 auto;
    }
    .tabmaneg::before{
        width: 34px;
        height: 34px;
        left: 50%;
        transform: translateX(-50%);
    }
    .listboxflx{
        flex-wrap: wrap;
    }
    .filenambox{
        width: 50%!important;
    }
    .fileavailable{
        width: calc(50% - 30px)!important;
    }
    .mainlistbox.gridmainbox{
        width: 100%;
    }
    .mainlistbox.gridmainbox .listboxflx{
        width: calc(95% - 20px);
    }
    .viewtype{
        display: none;
    }
    .filepath li a{
        font-size: 12px;
    }
    .filepath li{
        font-size: 12px;
    }
    .backbutton{
        font-size: 12px;
        padding: 8px 10px;
    }
    .flx-vids a svg{
        height: 16px;
        width: 16px;
    }
    .nextbutton{
        font-size: 12px;
        padding: 8px 10px;
    }
    .uploadsuc{
        display: none;
    }
    .instruclist li{
        font-size: 12px;
        align-items: center;
    }
    .instructbox{
        width: 95%!important;
    }
    .w50 .instructbox{
        width: 100%!important;
    }
    .questions-listside{
        position: fixed;
        right: -100%;
        height: 100vh;
        z-index: 99;
        top: 0;
        width: 100%!important;
        background: transparent;
        display: flex;
        transition: .5s;
    }
    .questions-listside .questblackarea{
        width: 20%;
        background: rgba(0,0,0,0.5);
    }
    .questions-listside .questwhitearea{
        width: 80%;
        max-height: calc(100% - 100px);
        overflow: auto;
        background: #fff;
    }
    .quizpage{
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-bottom: 60px;
        padding-top: 0;
    }
    .questionsside{
        width: 100%;
    }
    .rbuttons{
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        width: calc(100% - 20px);
        padding: 10px;
        border-top: 1px solid rgba(0,0,0,0.1);
    }
    .bottombarflx button{
        font-size: 12px;
    }
    /* .lbuttons span{
        display: none;
    } */
    .rbuttons  button{
        width: 33%;
        padding: 8px 0;
    }
    .quiztopflx{
        width: calc(100% - 20px)!important;
        border: 0;
        padding: 5px 10px;
        border-radius: 0;
    }
    .quiz-name p{
        font-size: 12px;
    }
    .submitquiz .instructions span{
        display: none;
    }
    .instructions svg{
        margin-right: 0;
        height: 14px;
        width: 14px;
    }
    .quizboxmain{
        width: 100%!important;
        border-radius: 0;
    }
    .quest-act p, .quest-type p{
        font-size: 12px;
    }
    .quiz-time{
        font-size: 12px;
    }
    .clockbox{
        display: none;
    }
    .answemainside{
        padding-top: 2px;
    }
    .mainquestionbox{
        max-height: initial;
        min-height: calc(100vh - 250px);
    }
    .instructionspop .instuctbox{
        width: 90%;
    }
    .submitquiz button{
        padding: 8px 20px;
        font-size: 12px;
    }
    .instructions{
        padding: 7px 10px;
    }
    .questopbflx{
        width: 95%!important;
    }
    .resultbx{
        flex-wrap: wrap;
        width: 100%!important;
    }
    .resultopbx {
        width: 100%!important;
    }
    .topbxresultmain  .w80{
        width: 80%;
    }
    .answersside{
        width: 100%!important;
    }
    .answers-topbar{
        flex-wrap: wrap;
    }
    .answerightside{
        width: 100%;
        margin: 5px auto;
    }
    .flx-vids{
        flex-wrap: wrap;
    }
    .videonambox p{
        font-size: 14px;
    }
    .plyr--video{
        height: auto!important;
    }
    .videobox-vidpage{
        min-height: auto!important;
    }
    .notgenerated {
        width: 90%!important;
    }
    .notimg{
        height: 200px;
    }
    .submitconfirmbox{
        width: 90%;
    }
    .bookingflx{
        justify-content: center;
    }
    .leaderstable th{
        white-space: nowrap;
    }
    .leaderstable{
        overflow: auto;
    }
    .leaderstable::-webkit-scrollbar {
        height: 5px;
    }
    .postpopdata{
        width: 90%;
    }
    .notavailpflex{
        width: 90%;
    }
    .notavailpflex .nodataimg{
        height: 200px;
    }
    .sidebarside{
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 9999;
        width: 100%;
    }
    .backflex{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap:10px;
        width: calc(100% - 20px)!important;
    }
    .closesbar {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: #333;
        color: #fff;
        text-align: center;
    }
    .backflex a{
        width: calc(100% - 45px);
    }
    
    .closesbar svg{   
        margin-top: 7px;
        height: 20px;
        width: 20px;
    }
    .contentside{
        width: 100%;
    }
    .topflflex{
        flex-wrap: wrap;
        gap: 10px;
    }
    .topnameside{
        width: 100%;
    }
    .fileimg{
        height: 30px;
        width: 30px;
    }
    .fileimagebox{
        width: 30px;
    }
    .datasidebox{
        width: calc(100% - 40px);
    }
    .getstartfile svg,.checked svg{
        height: 15px;
        width: 15px;
    }
    .filenambox p{
        font-size: 12px;
    }
    .newcircle{
        font-size: 8px;
    }
    .fileavailable p{
        font-size: 12px;
    }
    .topnameside .closemenu{
        display: none;
    }
    .reactions{
            
        width: calc(100% - 30px)!important;
    }
    .reaction-message{
        width: calc(100% - 30px)!important;
        margin: 0 auto;
    }
}
/* ******************Quiz End****************************** */
